import React from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { Paragraph } from "./components/paragraph";

const options = {
  renderNode: {
    [MARKS.BOLD]: (_, children) => <b>{children}</b>,
    [BLOCKS.PARAGRAPH]: (_, children) => <Paragraph>{children}</Paragraph>,
  },
};

export function renderRichText(json) {
  return documentToReactComponents(json, options);
}
