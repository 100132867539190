import PropTypes from "prop-types";
import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

import logo from "../images/weselove-logo-2.svg";

import { useActiveLinkHighlight } from "../components/useActiveLinkHighlight";
import { useLogoAnimation } from "./useLogoAnimation";
import { useScreenInfo } from "./useScreenInfo";
import { BREAKPOINTS } from "./grid";

import { IconArrowLeft } from "./icons/iconArrowLeft";
import { MobileMenuIcon } from "./mobileMenuIcon";
import { useLanguage } from "./useLanguage";
import { translate } from "../translate";

const HeaderHolder = styled.header`
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;
  height: 50px;
  color: #2a2c2b;
  font-weight: 600;
  font-size: 0.75rem;
  padding: 0 8px;
`;

const MaxWidthWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1196px;
  margin: 0 auto;
  height: 100%;
`;

const NavList = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  height: 100%;
`;

const NavItem = styled.li`
  border-top: ${({ isActive }) =>
    isActive ? `2px solid #2a2c2b` : "2px solid transparent"};
  display: flex;
  align-items: center;
  margin: 0 8px;
  height: 100%;
  white-space: nowrap;
`;

const Nav = styled.nav`
  height: 100%;

  @media (max-width: ${BREAKPOINTS.md}px) {
    visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};

    & ${NavList} {
      margin-top: 100px;
      flex-direction: column;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & ${NavItem} {
      height: 50px;
      border-top: none;
    }
  }
`;

const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease opacity;

  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 120px;
  }
`;

const NavLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000;
  height: 100%;
  cursor: pointer;
`;

const BackButtonIcon = styled(IconArrowLeft)`
  width: 13px;
  margin-right: 5px;
`;

const overlayAnimation = keyframes`
  from { opacity: 0 };
  to: { opacity: 1 };
`;

const Overlay = styled.div`
  background-color: #fff;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: 0.2s ${overlayAnimation} ease;
`;

export const Header = ({ menu }) => {
  const { isMediumDown } = useScreenInfo();
  const { language } = useLanguage();
  const { activeSection } = useActiveLinkHighlight(menu.list[0].hash);
  const { opacity } = useLogoAnimation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const invertedOpacity = 0.6 - opacity;

  function handleNavItemClick() {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  }

  return (
    <HeaderHolder fullscreen={isMenuOpen}>
      {isMenuOpen && <Overlay />}
      <MaxWidthWrapper>
        <NavList>
          <NavItem isActive={language === "pl"}>
            <NavLink href="/">pl</NavLink>
          </NavItem>
          <NavItem isActive={language === "en"}>
            <NavLink href="/en">en</NavLink>
          </NavItem>
        </NavList>
        <Logo
          src={logo}
          style={{ opacity: isMenuOpen ? 1 : invertedOpacity }}
        />
        <Nav isVisible={isMenuOpen}>
          <NavList>
            {menu.list.map(({ sectionId, hash, label }) => (
              <NavItem
                key={sectionId}
                isActive={activeSection === hash}
                onClick={handleNavItemClick}
              >
                <NavLink href={`#${hash}`}>{label}</NavLink>
              </NavItem>
            ))}
          </NavList>
        </Nav>
        {isMediumDown && (
          <MobileMenuIcon
            handleClick={() => setIsMenuOpen(!isMenuOpen)}
            isActive={isMenuOpen}
          />
        )}
      </MaxWidthWrapper>
    </HeaderHolder>
  );
};

export const SubPageHeader = () => {
  const { language } = useLanguage();

  function goBack() {
    window.history.back();
  }

  return (
    <HeaderHolder>
      <MaxWidthWrapper>
        <NavLink as="div" onClick={goBack}>
          <BackButtonIcon />
          {translate("back", language)}
        </NavLink>
        <Logo src={logo} />
      </MaxWidthWrapper>
    </HeaderHolder>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};
