import styled from "styled-components";
import dash from "../images/dash.svg";

export const Divider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 40px);
  width: 1px;
  margin: 20px 0;
  background: url(${dash});
  opacity: 0.5;
`;
