import React from "react";
import styled from "styled-components";

const facebookLogo = require("../images/facebook-logo.svg");
const instagramLogo = require("../images/instagram-logo.svg");

const SocialMediaIconsHolder = styled.div`
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
`;

const IconHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 2px 3px 0px #e0e0e0;
  transition: 0.2s ease transform, 0.2s ease box-shadow;

  &:hover {
    box-shadow: 0 3px 7px 0px #dddddd;
  }
`;

const FacebookIcon = styled.img`
  width: 13px;
`;

const InstagramIcon = styled.img`
  width: 28px;
`;

const StyledLinkWrapper = styled.a`
  &:hover {
    opacity: 1 !important;
  }
`;

export const SocialMediaIcons = ({ socialMediaUrls }) => {
  return (
    <SocialMediaIconsHolder>
      <StyledLinkWrapper
        href="https://www.facebook.com/Weseelove-106477354673666"
        target="_blank"
      >
        <IconHolder>
          <FacebookIcon src={facebookLogo} />
        </IconHolder>
      </StyledLinkWrapper>
      <StyledLinkWrapper
        href="http://www.instagram.com/staniakania"
        target="_blank"
      >
        <IconHolder>
          <InstagramIcon src={instagramLogo} />
        </IconHolder>
      </StyledLinkWrapper>
    </SocialMediaIconsHolder>
  );
};
