import styled from "styled-components";
import { BREAKPOINTS } from "./grid";

export const Section = styled.section`
  position: relative;
  padding-top: 100px;

  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 60px;
  }
`;
