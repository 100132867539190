import React from "react";

export const LanguageContext = React.createContext();

export function LanguageProvider({ children, language }) {
  return (
    <LanguageContext.Provider value={language || "pl"}>
      {children}
    </LanguageContext.Provider>
  );
}
