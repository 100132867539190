import React from "react";
import styled from "styled-components";

const IconHolder = styled.span`
  display: inline-flex;
  align-items: center;
  transform: rotate(180deg);
`;

export function IconArrowLeft(props) {
  return (
    <IconHolder {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.436"
        height="14.063"
        viewBox="0 0 20.436 14.063"
      >
        <g transform="translate(0 -41.916)">
          <path
            d="M20.158,48.276l-6.081-6.081a.95.95,0,1,0-1.344,1.344L17.192,48H.95a.95.95,0,1,0,0,1.9H17.192l-4.459,4.459A.95.95,0,0,0,14.077,55.7l6.081-6.081A.95.95,0,0,0,20.158,48.276Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </IconHolder>
  );
}
