import React from "react";
import { ScreenInfoContext } from "./screenInfoProvider";

export function useScreenInfo() {
  const screenSize = React.useContext(ScreenInfoContext);

  return {
    isSmallOnly: ["small"].includes(screenSize),
    isMediumDown: ["small", "medium"].includes(screenSize),
    isMediumUp: ["medium", "large", "xlarge"].includes(screenSize),
    isLargeDown: ["small", "medium", "large"].includes(screenSize),
    isLargeUp: ["large", "xlarge"].includes(screenSize),
    isXLargeOnly: ["xlarge"].includes(screenSize),
  };
}
