import { useState, useEffect, useRef } from "react";

export function useLogoAnimation() {
  const [style, setStyle] = useState({ opacity: 1, scale: 1 });
  const ref = useRef({ opacity: 1 });

  function handleCalculate() {
    const factor = 1 / 120;
    const opacity = 1 - window.scrollY * factor;
    const scale = 1 - window.scrollY * (1 / 2000);

    setStyle({ opacity, scale });
    ref.current.opacity = opacity;
  }

  useEffect(() => {
    function calculate() {
      if (window.pageYOffset < 300 || ref.current.opacity > 0) {
        window.requestAnimationFrame(handleCalculate);
      }
    }

    document.addEventListener("scroll", calculate);
    calculate();

    return () => document.removeEventListener("scroll", calculate);
  }, []);

  return {
    opacity: style.opacity,
    scale: style.scale,
  };
}
