import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { Button } from "./button";
import { Row, Column } from "./grid";
import { Subheading } from "./heading";
import { useLanguage } from "./useLanguage";
import { OfferList, OfferListItem, OfferCheckMark } from "./offer";

import { IconSwan } from "./icons/iconSwan";
import { IconStar } from "./icons/iconStar";
import { IconFlower } from "./icons/iconFlower";

import { renderRichText } from "../richTextRenderer";
import { translate as translateText } from "../translate";

const OfferHolder = styled.div`
  & > * + * {
    margin-top: 48px;
  }
`;

const IconHolder = styled.div`
  width: 55px;
  margin-bottom: 16px;
`;

const ICON_MAPPER = {
  0: (
    <IconHolder>
      <IconSwan />
    </IconHolder>
  ),
  1: (
    <IconHolder>
      <IconStar />
    </IconHolder>
  ),
  2: (
    <IconHolder>
      <IconFlower />
    </IconHolder>
  ),
};

export function OfferContentMobile({ items }) {
  const { language } = useLanguage();

  return (
    <OfferHolder>
      {items.map(
        ({ id, title, description, offerItems, url, hasMoreButton }, index) => {
          return (
            <Row key={id} justifyContent="center">
              <Column
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                xs={12}
              >
                {ICON_MAPPER[index]}
                <Subheading>{title}</Subheading>
              </Column>
              <Column xs={12} alignItems="center">
                {renderRichText(description.json)}
              </Column>

              {offerItems && (
                <Column xs={12} alignItems="center">
                  <OfferList>
                    {offerItems.map(({ description }, index) => {
                      if (index > 2) return null;

                      return (
                        <OfferListItem key={description.id}>
                          <OfferCheckMark />
                          {renderRichText(description.json)}
                        </OfferListItem>
                      );
                    })}

                    <OfferListItem>
                      <OfferCheckMark />
                      {translateText("andMuchMore", language)}
                    </OfferListItem>
                  </OfferList>
                </Column>
              )}

              {hasMoreButton && (
                <Column xs={12} alignItems="center">
                  <Link to={url}>
                    <Button>{translateText("seeMore", language)}</Button>
                  </Link>
                </Column>
              )}
            </Row>
          );
        }
      )}
    </OfferHolder>
  );
}
