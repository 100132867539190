import React from "react";

export function IconStar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
    >
      <path
        d="M14.867,30.839,9.511,48.828a.832.832,0,0,0,1.331.9l14.138-11.1C40.349,50.674,39.235,49.9,39.651,49.9a.832.832,0,0,0,.832-1.065L35.16,30.772,49.481,18.937a.832.832,0,0,0-.532-1.472H31.243L25.812.574a.832.832,0,0,0-1.58,0L18.718,17.465H1.011a.832.832,0,0,0-.532,1.472Zm31.778-11.71L34.652,29.042l-2.9-9.913ZM25.812,6.1c.765,2.5,12.317,40.752,12.317,40.752L25.812,37.159Zm-1.663,0V37.159L11.848,46.8C14.792,36.594,21.055,16.235,24.148,6.1Zm-5.93,13.032-2.844,9.98L3.357,19.129Z"
        transform="translate(-0.178 -0.002)"
        fill="currentColor"
      />
    </svg>
  );
}
