import { useEffect, useState, useRef } from "react";

export function useActiveLinkHighlight(initialValue) {
  const [activeSection, setActiveSection] = useState(initialValue);
  const ref = useRef({ waiting: false, sections: [] });

  function spyActiveSections() {
    const sections = document.getElementsByTagName("section");

    [...sections].forEach(section => {
      const elementTop = section.getBoundingClientRect().top;
      const elementBottom = section.getBoundingClientRect().bottom;

      if (elementTop < 50 && elementBottom > 50) {
        setActiveSection(section.id);
      }
    });
  }

  useEffect(() => {
    let _timeout = null;

    document.addEventListener("scroll", () => {
      if (ref.current.waiting) {
        return;
      }
      ref.current.waiting = true;

      spyActiveSections();

      _timeout = setTimeout(function() {
        ref.current.waiting = false;
      }, 50);
    });

    return () => clearTimeout(_timeout);
  }, []);

  return { activeSection };
}
