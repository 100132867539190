import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Header, SubPageHeader } from "./header";
import { Footer } from "./footer";
import "./layout.css";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;

const Main = styled.main`
  width: 100%;
  padding-bottom: 128px;
  padding-top: ${({ subPage }) => (subPage ? "64px" : 0)};
  overflow: hidden;
`;

export function Layout({ children, menu, subPage }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Wrapper>
      {subPage ? (
        <SubPageHeader siteTitle={data.site.siteMetadata.title} />
      ) : (
        <Header siteTitle={data.site.siteMetadata.title} menu={menu} />
      )}
      <MainWrapper>
        <Main subPage={subPage}>{children}</Main>
      </MainWrapper>
      <Footer />
    </Wrapper>
  );
}
