import React from "react";
import { LanguageContext } from "./languageProvider";

export function useLanguage() {
  const language = React.useContext(LanguageContext);

  if (!language) {
    throw new Error("Please wrap your page with <LanguageProvider />");
  }

  return { language };
}
