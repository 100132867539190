import React from "react";
import styled from "styled-components";

import { IconArrowRight } from "./icons/iconArrowRight";

const Icon = styled(IconArrowRight)`
  padding-left: 32px;
  padding-right: 8px;
  transition: 0.2s ease padding;
`;

export const ButtonHolder = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 32px;
  margin-top: ${({ topSpacing }) => (topSpacing ? 32 : 0)}px;
  margin-bottom: ${({ bottomSpacing }) => (bottomSpacing ? 32 : 0)}px;
  font-weight: bold;
  color: #fff;
  white-space: nowrap;
  background-color: #bc649d;
  border-radius: 50px;
  border: none;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    transition: 0.2s ease all;

    & ${Icon} {
      padding-left: 40px;
      padding-right: 0px;
    }
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    color: #d2d1d1;
    background-color: gray;
    pointer-events: none;
  }
`;

export function Button({
  children,
  topSpacing = false,
  bottomSpacing = false,
  ...props
}) {
  return (
    <ButtonHolder
      topSpacing={topSpacing}
      bottomSpacing={bottomSpacing}
      {...props}
    >
      {children}
      <Icon />
    </ButtonHolder>
  );
}
