import React from "react";

export function IconFlower() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 60 60"
    >
      <path
        d="M2.215,26.095l10.831-2.369L20.7,34.466l-.9.662H4.011a1,1,0,0,0-.662,1.757C32.4,62.7,30.552,61.227,31.114,61.227s-1.476,1.646,27.765-24.342a1,1,0,0,0-.663-1.757H42.477l-.9-.662,7.659-10.741,10.781,2.369a1,1,0,0,0,.893-1.716L47.857,12.333a1,1,0,0,0-.683-.291H38.552l1.3-1.295a1,1,0,0,0,0-1.425l-8.03-8.03a1,1,0,0,0-1.425,0l-8.03,8.03a1,1,0,0,0,0,1.425l1.3,1.295H15.053a1,1,0,0,0-.683.271L1.321,24.358a1,1,0,0,0,.893,1.737Zm12.909-2.811,9.355-2.008,3.232,8.1-5.43,3.9ZM6.651,37.136H19.43L28.213,56.3ZM31.114,57.814,21.327,36.464l9.787-7.117L40.9,36.464ZM42.8,37.136H55.576L34.015,56.3Zm-2.851-3.855-5.43-3.945,3.232-8.1L47.1,23.244Zm16.884-9.958L39.6,19.58l7.488-5.25ZM43.992,14.049c-8.03,5.641-7.629,5.25-7.789,5.651l-2.078,5.2V16.468l2.419-2.419ZM31.114,3.419l6.615,6.615-5.32,5.31a1,1,0,0,0-.291.713V27.59l-.412-.3a1,1,0,0,0-1.184,0l-.412.3V16.057a1,1,0,0,0-.291-.713l-5.32-5.31Zm-5.43,10.63L28.1,16.468V24.9l-2.078-5.2c-.161-.4.261,0-7.789-5.651Zm-10.54.281,7.488,5.25L5.4,23.324Z"
        transform="translate(-0.971 -0.994)"
        fill="currentColor"
      />
    </svg>
  );
}
