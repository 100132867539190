import styled from "styled-components";
import { BREAKPOINTS } from "./grid";

export const Heading = styled.h1`
  @media (max-width: ${BREAKPOINTS.md}px) {
    text-align: center;
  }
`;

export const Subheading = styled.h2`
  @media (max-width: ${BREAKPOINTS.md}px) {
    text-align: center;
  }
`;
