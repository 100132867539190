import React from "react";
import styled from "styled-components";
import { SocialMediaIcons } from "./socialMediaIcons";

import { Row, Column } from "./grid";
import { Paragraph } from "./paragraph";
import { translate } from "../translate";
import { useLanguage } from "./useLanguage";
import { useScreenInfo } from "./useScreenInfo";

import malinowskiLogo from "../images/malinowski-logo.svg";

const FooterHolder = styled.footer`
  position: relative;
  display: flex;
  min-height: 150px;
  background-color: #fff;
  margin-top: auto;
  font-size: 0.8rem;
  padding-top: 64px;
`;

const MalinowskiLogo = styled.img`
  margin-bottom: 16px;
`;

export function Footer() {
  const { language } = useLanguage();
  const { isMediumDown } = useScreenInfo();

  return (
    <FooterHolder>
      <Row>
        <Column
          xs={12}
          lg={6}
          alignItems={isMediumDown ? "center" : "flex-end"}
        >
          <Paragraph textAlign={isMediumDown ? "center" : "left"}>
            {translate("footerPhotosAuthors", language)} Marcin Marcinkiewicz,
            Krzysztof Morawiecki, Jan Obuszyński
          </Paragraph>
        </Column>
        <Column
          xs={12}
          lg={6}
          justifyContent="flex-end"
          alignItems={isMediumDown ? "center" : "flex-end"}
        >
          <MalinowskiLogo src={malinowskiLogo} />
          <Paragraph textAlign={isMediumDown ? "center" : "right"}>
            {translate("allRightsReserved", language)}{" "}
            {new Date().getFullYear()}
          </Paragraph>
        </Column>
      </Row>
      <SocialMediaIcons />
    </FooterHolder>
  );
}
