export const DICTIONARY = {
  pl: {
    back: "Powrót",
    footerPhotosAuthors: "Zdjęcia zamieszczone na stronie wykonali:",
    allRightsReserved: "Wszelkie prawa zastrzeżone © Weseelove",
    andMuchMore: "...i wiele więcej",
    seeMore: "Dowiedz się więcej",
    name: "Imię",
    optionally: "opcjonalnie",
    message: "Wiadomość",
    send: "Wyślij",
    whatDoYouWantToAsk: "O co chcesz zapytać?",
    namePlaceholder: "Kasia",
    emailPlaceholder: "kasia@gmail.com",
    showPhone: "Pokaż numer",
    showEmail: "Pokaż email",
    smartphoneIcon: "Ikona smartfona",
    emailIcon: "Ikona maila",
  },
  en: {
    back: "Back",
    footerPhotosAuthors: "Website photos taken by:",
    allRightsReserved: "All rights reserved © Weseelove",
    andMuchMore: "...and much more",
    seeMore: "See more",
    name: "Name",
    optionally: "optionally",
    message: "Message",
    send: "Send",
    whatDoYouWantToAsk: "What do you want to ask?",
    namePlaceholder: "Kathy",
    emailPlaceholder: "kathy@gmail.com",
    showPhone: "Show phone",
    showEmail: "Show email",
    smartphoneIcon: "Smartphone icon",
    emailIcon: "Email icon",
  },
};

export function translate(phrase, language) {
  return DICTIONARY[language || "pl"][phrase];
}
