import React from "react";
import styled from "styled-components";

const CUT_POSITION_MAP = {
  topRight: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 291.756 290.858"
    >
      <defs>
        <filter
          id="a"
          x="0"
          y="0"
          width="100%"
          height="100%"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="15" result="b" />
          <feFlood floodOpacity="0.102" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#a)">
        <path
          d="M0,56.069,56.069,0H200.858V201.756H0V56.069Z"
          transform="translate(246.76 42) rotate(90)"
          fill="currentColor"
        />
      </g>
    </svg>
  ),
  topLeft: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 290.858 291.756"
    >
      <defs>
        <filter
          id="a"
          x="0"
          y="0"
          width="100%"
          height="100%"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="15" result="b" />
          <feFlood floodOpacity="0.102" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#a)">
        <path
          d="M0,56.069,56.069,0H200.858V201.756H0Z"
          transform="translate(45 42)"
          fill="currentColor"
        />
      </g>
    </svg>
  ),
  bottomRight: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 290.858 291.756"
    >
      <defs>
        <filter
          id="a"
          x="0"
          y="0"
          width="100%"
          height="100%"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="15" result="b" />
          <feFlood floodOpacity="0.102" />
          <feComposite operator="in" in2="b" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#a)">
        <path
          d="M0,56.069,56.069,0H200.858V201.756H0Z"
          transform="translate(245.86 243.76) rotate(180)"
          fill="currentColor"
        />
      </g>
    </svg>
  ),
};

const CutSquareHolder = styled.div`
  position: relative;
  color: ${({ isActive }) => (isActive ? "#1B2934" : "#fff")};
  transform: ${({ isActive }) =>
    isActive ? "scale3d(1.2, 1.2, 1.2)" : "scale3d(1, 1, 1)"};
  transition: 0.3s ease all;
  cursor: pointer;
`;

const CutSquareContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 170px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ isActive }) => (isActive ? "#fff" : "#1B2934")};
`;

const IconHolder = styled.div`
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 0.6rem;
  text-align: center;
  line-height: 1.5em;
  text-transform: uppercase;
  margin-top: 16px;
`;

export function CutSquare({
  cutPosition = "topRight",
  icon,
  title,
  isActive,
  handleClick,
}) {
  return (
    <CutSquareHolder isActive={isActive} onClick={handleClick}>
      {CUT_POSITION_MAP[cutPosition]}
      <CutSquareContent isActive={isActive}>
        <IconHolder>{icon}</IconHolder>
        {title && <Title>{title}</Title>}
      </CutSquareContent>
    </CutSquareHolder>
  );
}
