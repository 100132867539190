import React from "react";

export function IconSwan() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
    >
      <path
        d="M49.785,38.009h0l-14.6-25.366H49.069a.832.832,0,0,0,.463-1.523l-14.9-9.98a.832.832,0,0,0-1.1.156C24.368,12.2,24.934,11.379,24.95,11.852c.01.2-.286-.67,5.014,13.781L9.449,17.689a.832.832,0,0,0-1.07,1.093L13.1,30.238,1,27.77a.832.832,0,0,0-.8,1.353l18.3,21.485a.832.832,0,0,0,.634.293h19.96a.832.832,0,0,0,.631-.291L49.7,38.966A.816.816,0,0,0,49.785,38.009Zm-17.5,6.046L6.449,30.579l39.684,8.1ZM34.322,2.938,46.332,10.98H27.567Zm-1.055,9.706,14.162,24.6L35.07,34.721l-8.1-22.077ZM33.154,34.33,27.142,33.1l-13.9-12.16,17.484,6.77ZM11.267,21.423,23.848,32.431l-8.785-1.792ZM4.242,31.3,30.229,44.859,19.378,49.08ZM38.706,49.237H23.56L46.279,40.4Z"
        transform="translate(0 -0.999)"
        fill="currentColor"
      />
    </svg>
  );
}
