import React from "react";
import { func, bool } from "prop-types";
import styled, { keyframes, css } from "styled-components";

const LINE_HEIGHT = 2;
const LINE_WIDTH = LINE_HEIGHT * 6;
const ANIMATION_DURATION = "0.3s";

const MobileMenuIconHolder = styled.div`
  display: flex;
  align-items: center;
  color: #2a2c2b;
  font-weight: 600;
  font-size: 0.75rem;
  cursor: pointer;
`;

const topLineAnimationIn = keyframes`
  0% { transform: translate(0, -${LINE_HEIGHT * 2}px); }
  50% {transform: translate(0, 0); }
  100% {transform: translate(0, 0) rotate(45deg); }
`;

const topLineAnimationOut = keyframes`
  0% {transform: translate(0, 0); }
  100% { transform: translate(0, -${LINE_HEIGHT * 2}px); }
`;

const middleLineAnimationIn = keyframes`
  0% { opacity: 1 }
  100% { opacity: 0 }
`;

const middleLineAnimationOut = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const bottomLineAnimationIn = keyframes`
  0% { transform: translate(0, ${LINE_HEIGHT * 2}px); }
  50% {transform: translate(0, 0); }
  100% {transform: translate(0, 0) rotate(-45deg); }
`;

const bottomLineAnimationOut = keyframes`
  0% {transform: translate(0, 0); }
  100% { transform: translate(0, ${LINE_HEIGHT * 2}px); }
`;

const LinesHolder = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
`;

const LineBase = styled.div`
  position: absolute;
  left: calc(50% - ${LINE_WIDTH / 2}px);
  height: ${LINE_HEIGHT}px;
  width: ${LINE_WIDTH}px;
  border-radius: ${LINE_HEIGHT / 2}px;
  background-color: #000;
`;

const TopLine = styled(LineBase)`
  top: calc(50% - ${LINE_HEIGHT / 2}px);
  transform: translate(0, -${LINE_HEIGHT * 2}px);
  animation: ${({ isActive }) =>
    isActive
      ? css`
          ${topLineAnimationIn} ${ANIMATION_DURATION} ease
        `
      : css`
          ${topLineAnimationOut} ${ANIMATION_DURATION} ease
        `};
  animation-fill-mode: forwards;
`;

const MiddleLine = styled(LineBase)`
  top: calc(50% - ${LINE_HEIGHT / 2}px);
  animation: ${({ isActive }) =>
    isActive
      ? css`
          ${middleLineAnimationIn} ${ANIMATION_DURATION} ease
        `
      : css`
          ${middleLineAnimationOut} ${ANIMATION_DURATION} ease
        `};
  animation-fill-mode: forwards;
`;

const BottomLine = styled(LineBase)`
  top: calc(50% - ${LINE_HEIGHT / 2}px);
  transform: translate(0, ${LINE_HEIGHT * 2}px);
  animation: ${({ isActive }) =>
    isActive
      ? css`
          ${bottomLineAnimationIn} ${ANIMATION_DURATION} ease
        `
      : css`
          ${bottomLineAnimationOut} ${ANIMATION_DURATION} ease
        `};
  animation-fill-mode: forwards;
`;

const MenuText = styled.div`
  margin-bottom: 3px;
  margin-right: -4px;
`;

export const MobileMenuIcon = ({ handleClick, isActive }) => {
  return (
    <MobileMenuIconHolder onClick={handleClick}>
      <MenuText>menu</MenuText>
      <LinesHolder>
        <TopLine isActive={isActive} />
        <MiddleLine isActive={isActive} />
        <BottomLine isActive={isActive} />
      </LinesHolder>
    </MobileMenuIconHolder>
  );
};

MobileMenuIcon.propTypes = {
  handleClick: func.isRequired,
  isActive: bool.isRequired,
};
