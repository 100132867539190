import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { CutSquare } from "./cutSquare";
import { Button } from "./button";
import { IconSwan } from "./icons/iconSwan";
import { IconStar } from "./icons/iconStar";
import { IconFlower } from "./icons/iconFlower";
import { Row, Column } from "./grid";
import { Divider } from "./divider";
import { useLanguage } from "./useLanguage";
import { OfferColumn, OfferList, OfferListItem, OfferCheckMark } from "./offer";

import { renderRichText } from "../richTextRenderer";
import { translate as translateText } from "../translate";

const ITEMS_WEIGHT = {
  comprehensive: 3,
  partial: 2,
  coordination: 1,
};

const IconHolder = styled.div`
  max-width: 60px;
`;

const ICON_MAPPER = {
  0: (
    <IconHolder>
      <IconSwan />
    </IconHolder>
  ),
  1: (
    <IconHolder>
      <IconStar />
    </IconHolder>
  ),
  2: (
    <IconHolder>
      <IconFlower />
    </IconHolder>
  ),
};

const CUT_POSITION_MAPPER = {
  0: "topRight",
  1: "topLeft",
  2: "bottomRight",
};

const OfferContentDesktopHolder = styled.div`
  width: 100%;
  overflow: hidden;
`;

const FullWidthRow = styled.div`
  width: 100%;
  background-color: transparent;
`;

const OfferOptionsHolder = styled.div`
  display: flex;
`;

const OfferContentHolder = styled.div`
  display: flex;
  overflow: hidden;
  width: 300%;
`;

export function OfferContentDesktop({ items }) {
  const { language } = useLanguage();

  const [activeOfferIndex, setActiveOfferIndex] = useState(0);
  const [height, setHeight] = useState("auto");
  const [translate, setTranslate] = useState(0);

  const itemsRef = useRef([]);

  function calculateHeight(element) {
    if (element) {
      const clientRect = element.getBoundingClientRect();
      setHeight(clientRect.height);
    }
  }

  function calculateTranslate(elementIndex) {
    const value = {
      0: 0,
      1: -100 / 3,
      2: (-100 / 3) * 2,
    };
    setTranslate(value[elementIndex]);
  }

  function offerItemsSort(a, b) {
    return ITEMS_WEIGHT[b.uniqueId] - ITEMS_WEIGHT[a.uniqueId];
  }

  useEffect(() => {
    function handleCalculateHeight() {
      calculateHeight(itemsRef.current[0]);
    }

    function handleCalculateTranslate() {
      calculateTranslate(0);
    }

    handleCalculateHeight();
    handleCalculateTranslate();

    window.addEventListener("resize", handleCalculateHeight);

    return () => window.removeEventListener("resize", handleCalculateHeight);
  }, []);

  return (
    <OfferContentDesktopHolder>
      <Row justifyContent="center">
        <Column md={12} lg={10} alignItems="center">
          <OfferOptionsHolder>
            {items.sort(offerItemsSort).map(({ id, title }, index) => {
              return (
                <CutSquare
                  key={id}
                  cutPosition={CUT_POSITION_MAPPER[index]}
                  icon={ICON_MAPPER[index]}
                  title={title}
                  isActive={index === activeOfferIndex}
                  handleClick={() => {
                    setActiveOfferIndex(index);
                    calculateHeight(itemsRef.current[index]);
                    calculateTranslate(index);
                  }}
                />
              );
            })}
          </OfferOptionsHolder>
        </Column>
      </Row>

      <OfferContentHolder
        style={{
          height: height ? `${height}px` : "auto",
          transform: `translate(${translate}%)`,
        }}
      >
        {items.map(
          ({ id, offerItems, description, url, hasMoreButton }, index) => {
            const hasOfferItems = offerItems && offerItems.length > 0;

            return (
              <FullWidthRow key={id}>
                <Row
                  ref={el => (itemsRef.current[index] = el)}
                  style={{
                    opacity: activeOfferIndex === index ? 1 : 0,
                    transition: "0.5s ease opacity",
                  }}
                >
                  <OfferColumn
                    left
                    xs={12}
                    md={hasOfferItems ? 6 : 12}
                    alignItems={hasOfferItems ? "flex-start" : "center"}
                  >
                    {renderRichText(description.json)}
                  </OfferColumn>

                  {hasOfferItems && (
                    <OfferColumn right xs={12} md={6}>
                      <Divider />
                      <OfferList>
                        {offerItems.map(({ description }, index) => {
                          if (index > 4) return null;

                          return (
                            <OfferListItem key={description.id}>
                              <OfferCheckMark />
                              {renderRichText(description.json)}
                            </OfferListItem>
                          );
                        })}
                        <OfferListItem>
                          <OfferCheckMark />
                          {translateText("andMuchMore", language)}
                        </OfferListItem>
                      </OfferList>

                      {hasMoreButton && (
                        <Link to={url}>
                          <Button topSpacing>
                            {translateText("seeMore", language)}
                          </Button>
                        </Link>
                      )}
                    </OfferColumn>
                  )}
                </Row>
              </FullWidthRow>
            );
          }
        )}
      </OfferContentHolder>
    </OfferContentDesktopHolder>
  );
}
