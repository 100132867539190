import React from "react";
import styled from "styled-components";

import { Section } from "./section";
import { Row, Column } from "./grid";
import { OfferContentDesktop } from "./offerContentDesktop";
import { OfferContentMobile } from "./offerContentMobile";
import { useScreenInfo } from "./useScreenInfo";
import { Heading } from "./heading";
import { IconCheckMark } from "./icons/iconCheckMark";

export const OfferColumn = styled(Column)`
  padding-right: ${({ left }) => (left ? "32px" : undefined)};
  padding-left: ${({ right }) => (right ? "32px" : undefined)};
`;

export const OfferList = styled.ul`
  margin: 0;
  list-style-type: none;
`;

export const OfferListItem = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const OfferCheckMark = styled(IconCheckMark)`
  margin-right: 8px;
  margin-top: 4px;
  color: #bc649d;
`;

export function Offer({ heading, items, hash }) {
  const { isLargeUp } = useScreenInfo();

  return (
    <Section id={hash}>
      <Row>
        <Column xs={12} alignItems="center">
          <Heading>{heading}</Heading>
        </Column>

        {isLargeUp ? (
          <OfferContentDesktop items={items} />
        ) : (
          <OfferContentMobile items={items} />
        )}
      </Row>
    </Section>
  );
}
